<app-header-component [isMarketplace]="true"></app-header-component>

<div style="background-color: #7514e3; display: flex; justify-content: center; align-items: center; padding: 0 1rem;">
  <div style="width: 1216px;padding: 1rem 0;height: 78.63px;">
    <app-search-input class="search-bar"></app-search-input>
  </div>
</div>


<!-- <app-hero-component *ngIf="!isLoading"></app-hero-component> -->

<div class="banner">
  <div class="left">
    <img src="../../assets/media/images/2024/fall_2024.png" alt="back2school" (click)="redirectTo('weeklyDeals')" />
  </div>
  <div class="right">
    <img *ngIf="isDesktop" src="../../assets/media/images/2024/fall_right_2024.png" alt="doordash" class="left-img"
      (click)="redirectTo('doordash')">
    <img *ngIf="!isDesktop" src="../../assets/media/images/2024/fall_right_2024.png" alt="doordash" class="left-img"
      (click)="redirectTo('doordash')">
    <img *ngIf="isDesktop" src="../../assets/media/images/2024/shop-r2.png" alt="spotify"
      (click)="redirectTo('spotify')">
    <img *ngIf="!isDesktop" src="../../assets/media/images/2024/shop-r2-s.png" alt="spotify"
      (click)="redirectTo('spotify')">
  </div>
</div>

<div class="page-title-desc">
  <h1>Shop Gift Cards</h1>
  <p>Whether you're shopping for birthdays, holidays, or for herself, we have the perfect gift card for everyone on your
    gift list. Earn cash back or bonus rewards as you shop over 250 brands across Canada.
  </p>
</div>



<div style="position: relative; min-height: 150px;" *ngIf="isLoading">
  <div class="spinner-container-2">
    <mat-spinner mat-primary></mat-spinner>
    <br>
    <span>Loading Deals</span>
  </div>
</div>
<div id="filter-section" *ngIf="!isLoading">
  <!-- <app-search-input class="search-bar me-3"></app-search-input> -->
  <swiper class="search-bar-swiper" [config]="swiperFilterConfig" [breakpoints]="swiperFilterBreakPoints"
    ng-reflect-id="filter-section">
    <!-- <ng-template swiperSlide>
      <button mat-stroked-button class="filter-btn all-btn" (click)="categoryBtnOnClick(null)">All</button>
    </ng-template> -->
    <ng-template swiperSlide *ngFor="let category of categoryItems">
      <div>
        <button class="filter-btn" (click)="categoryBtnOnClick(category)">
          <img [src]="getCategoryIcon(category)" />
          <p>{{category.text}}</p>
        </button>
      </div>
    </ng-template>
  </swiper>
  <div class="filter-nav-btn-group" style="margin-bottom: 45px">
    <div class="btn-grp">
      <button mat-stroked-button (click)="navBtnClick('swiper-button-prev', { name: 'filter-section'})"
        id="filter-btn-left"><mat-icon>keyboard_arrow_left</mat-icon></button>
      <button mat-stroked-button (click)="navBtnClick('swiper-button-next', { name: 'filter-section'})"
        id="filter-btn-right"><mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
  </div>
</div>

<div id="content-section" *ngIf="!isLoading">
  <ng-container *ngFor="let category of categories; let i = index">
    <div class="category-row">
      <div class="category-header">
        <h2 class="category-heading">{{category.text}}</h2>
        <div class="category-btn-grp">
          <a (click)="seeAllMerchants(category.enumValue)" class="view-all-link">View All</a>
          <button mat-mini-fab (click)="navBtnClick('swiper-button-prev', { name: category.enumValue})"
            id='{{category.enumValue}}-left'><mat-icon>keyboard_arrow_left</mat-icon></button>
          <button mat-mini-fab (click)="navBtnClick('swiper-button-next', { name: category.enumValue})"
            id='{{category.enumValue}}-right'><mat-icon>keyboard_arrow_right</mat-icon></button>
        </div>
      </div>
      <div [id]="category.enumValue">
        <swiper [config]="swiperDealConfig" [breakpoints]="swiperDealBreakPoints">
          <ng-template swiperSlide *ngFor="let dealMerchant of getDeals(category.dealIds); let i = index">
            <div class="card"
              *ngIf="dealMerchant && dealMerchant != null && dealMerchant.merchant.name && dealMerchant.merchant.name !== 'SeeAll'"
              [ngClass]="getMerchantExtraInfo(dealMerchant, category)"
              (click)="cardClickHandle(dealMerchant, category.enumValue)">
              <div [class]="isHeroImg(dealMerchant) ? 'card-img-hero' : 'card-img-logo'">
                <img *ngIf="isHeroImg(dealMerchant)" class="card-logo-corner" src="{{getLogoImage(dealMerchant)}}"
                  alt="Logo">
                <img class="card-img-top" src="{{getMerchantImage(dealMerchant)}}" alt="Card image cap">
                <!-- <img class="card-img-top" src="../../assets/media/images/TestHeroImage.png" alt="Card image cap"> -->
              </div>
              <div class="card-body">
                <h5 class="card-title"
                  *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length > 12">
                  {{dealMerchant.merchant.name| slice:0:12 }}...</h5>
                <h5 class="card-title"
                  *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length <= 15">
                  {{dealMerchant.merchant.name}}</h5>
                <span class="card-text" *ngIf="dealMerchant.bonusValType == 'PercentageOff' ||
                dealMerchant.bonusValType == 'MoolaCash'">Buy Now And Get</span>
                <span class="card-text" *ngIf="dealMerchant.bonusValType == 'FixedCard'">Buy
                  ${{dealMerchant.faceVal/100}} and Get</span>
                <div class="card-text">
                  <div class="card-title-second" *ngIf="dealMerchant && dealMerchant.bonusValType=='FixedCard'">
                    ${{dealMerchant.savedAmountInCents/100}} Bonus</div>
                  <div class="card-title-second"
                    *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType!='MoolaCash' && dealMerchant.discountPercent > 0">
                    {{dealMerchant.discountPercent}}% OFF</div>
                  <div class="card-title-second"
                    *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='MoolaCash' && dealMerchant.discountPercent > 0">
                    {{dealMerchant.discountPercent / 100}}% Moola Cash</div>
                  <div class="card-title-second"
                    *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='PercentageOff' && dealMerchant.discountPercent > 0">
                    {{dealMerchant.discountPercent}}% OFF</div>
                  <div class="card-title-second"
                    *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && (dealMerchant.bonusValType=='MoolaCash') && dealMerchant.discountPercent > 0">
                    {{dealMerchant.discountPercent / 100}}% More</div>
                  <div *ngIf="dealMerchant && dealMerchant.merchant.name && !dealMerchant.discountPercent"
                    class="invisible">_</div>
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>
      </div>

      <!-- Disable the banner -->
      <!-- <div *ngIf="((i+1) % 3) == 0 ">
        <div *ngIf="featuredDeals && ((i + 1)/3 - 1) < featuredDeals.length">
          <ng-container [ngTemplateOutlet]="featuredBanner"
            [ngTemplateOutletContext]="{ featuredDeal: getFeaturedBanner(i)}">
          </ng-container>
          <ng-template #featuredBanner let-featureBanner="featuredDeal">
            <div *ngIf="featureBanner" class="banner-container">
              <img src="{{getFeatureBannerImage(featureBanner.featureWebImg)}}" class="ad-banner"
                alt="featureBanner.merchant.name" (click)="onFeatureBannerClick(featureBanner)">
            </div>
          </ng-template>
        </div>
        <div
          *ngIf="dealsInfo.campaigns && (!featuredDeals || (featuredDeals && ((i + 1)/3 - 1) >= featuredDeals.length))">
          <ng-container [ngTemplateOutlet]="adBanner" [ngTemplateOutletContext]="{ banner: getBanner(i)}">1

          </ng-container>
          <ng-template #adBanner let-banner="banner">
            <div *ngIf="banner" class="banner-container">
              <img src="{{getBannerImage(banner.bannerImg)}}" class="ad-banner" alt="{{getBannerName(banner)}}"
                (click)="seeAllMerchants(banner.category.enumValue)">
            </div>
          </ng-template>
        </div>
      </div> -->
    </div>
  </ng-container>
</div>

<div class="text-box-section" *ngIf="!isLoading">
  <div class="first text-box">
    <h3>Save on average $500 more per year!</h3>
    <p>Get deals on gift cards for essential shops and services like gas, groceries, health & beauty, food delivery, and
      much more. After all, it all adds up to great savings!</p>
    <div class="btn-container">
      <button class="textbox-btn" (click)="redirectTo('how')">How Moola Saves You Money</button>
    </div>
  </div>
  <div class="second text-box">
    <h3>Want up to 15% cash back?</h3>
    <p>Sign up for email notifications and get notified on weekly deals up to 15% cash back rewards.</p>
    <div class="btn-container">
      <input type="email" [formControl]="emailFormControl" placeholder="Ex. doe@example.com">
      <button class="email-btn" mat-stroked-button (click)="submitEmailAddress()" [disabled]="emailFormControl.invalid"
        [ngClass]="emailFormControl.invalid ? 'email-btn-invalid':''">Subscribe</button>
      <p *ngIf="emailFormControl.dirty && emailFormControl.invalid && emailFormControl.value.length > 0"
        style="color: red;">Please provide a valid email.</p>
    </div>
  </div>
</div>

<div class="qAndA-section" *ngIf="!isLoading">
  <h1>Frequently Asked Questions</h1>
  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>How do I pay using gift cards?</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>All gift cards you purchase from Moola are genuine and purchased from the brands directly. To redeem in-store,
        simply show the cashier the gift card barcode found on the Moola App. To redeem online, you can copy & paste the
        gift card number found on the gift card email you received.</p>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>How do I get my cash back?</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Open the Moola App, and you can find your cash back balance on the top left corner of the screen. To redeem
        your cash back for future purchases, toggle the Pay with Moola Cash button at checkout.</p>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>How do I check my remaining gift card balance?</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>There are 3 ways to check your balance. </p>
      <ol>
        <li>Select your gift card within your Moola wallet and click the refresh icon below your gift card.</li>
        <li>Select your gift card within your Moola wallet, click Balance and click the Refresh or Check Balance
          prompt. </li>
        <li>Visit the merchant's website to check your gift card balance.</li>
      </ol>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Do I need to be connected to internet to use Moola gift cards?</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>You do not need to be connected to the internet to redeem an eGift card using Moola as it is available for use
        offline. This makes keeping to your budget easy with Moola no matter where you are.</p>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Do you sell digital gift cards?</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>All gift cards sold on Moola are digital gift cards (e-gift cards). Gift cards can be claimed online or
        in-store.</p>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Why is my gift card not working?</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>Please contact us at <a href="mailto:support@moola.com">support&#64;moola.com</a>. Our dedicated Support team is
        available 7 days a week.</p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<app-footer-component *ngIf="!isLoading"></app-footer-component>